// utils/deliveryDates.js

import { DeliveryDateInfo } from "../slices/deliveryWindowSlice";

/**
 * Returns the next three delivery dates based on predefined delivery days.
 * 
 * Delivery days are represented as numbers, where 0 is Sunday, 1 is Monday, and so on.
 * The function will skip dates that fall on the current day.
 * 
 * @returns {Array} An array of objects representing the next three delivery dates. 
 * Each object contains:
 * - `day`: Name of the day (e.g., "Tuesday").
 * - `date`: Formatted date (e.g., "Oct 23").
 * - `daysFromNow`: Number of days from the current date to the delivery date.
 * 
 * @example
 * 
 * const nextDates = getNextDeliveryDates();
 * console.log(nextDates);
 * // Outputs: 
 * // [
 * //   { day: "Tuesday", date: "Oct 23", daysFromNow: 1 },
 * //   { day: "Wednesday", date: "Oct 24", daysFromNow: 2 },
 * //   { day: "Thursday", date: "Oct 25", daysFromNow: 3 }
 * // ]
 * // This assumes today is Oct 22nd and delivery days are set to Tuesday, Wednesday, and Thursday.
 */
enum DayOfWeek {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6
}

export function getNextDeliveryDates(): DeliveryDateInfo[] {
  const deliveryDays: number[] = [DayOfWeek.Wednesday, DayOfWeek.Thursday];
  const today: Date = new Date();
  let currentDate: Date = new Date();
  const deliveryDates: Date[] = [];

  while (deliveryDates.length < 2) {
    currentDate.setDate(currentDate.getDate() + 1);
    if (deliveryDays.includes(currentDate.getDay()) && currentDate.getDate() !== today.getDate()) {
      deliveryDates.push(new Date(currentDate));
    }
  }
  
  return deliveryDates.map(date => ({
    day: date.toLocaleString('en-US', { weekday: 'short' }),
    date: date.toLocaleString('en-US', { month: 'short', day: 'numeric' }),
    daysFromNow: Math.floor((date.getTime() - today.getTime()) / (1000 * 60 * 60 * 24)),
  }));
}
 

/**
 * Returns today's date in the format "Tuesday, Oct 23rd".
 * 
 * @returns {string} Formatted string of today's date.
 * 
 * @example
 * 
 * const formattedDate = getFormattedTodayDate();
 * console.log(formattedDate); // "Tuesday, Oct 23rd" (assuming today is Oct 23rd)
 */
export function getFormattedTodayDate(): string {
  const date = new Date();

  // Day of the week
  const days = ["Sun", "Mon", "Tues", "Wed", "Thurs", "Fri", "Sat"];
  const dayName = days[date.getDay()];

  // Month name
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const monthName = months[date.getMonth()];

  // Date with ordinal suffix
  const dateNumber = date.getDate();
  let suffix = "th";
  if (dateNumber === 1 || dateNumber === 21 || dateNumber === 31) {
      suffix = "st";
  } else if (dateNumber === 2 || dateNumber === 22) {
      suffix = "nd";
  } else if (dateNumber === 3 || dateNumber === 23) {
      suffix = "rd";
  }

  return `${dayName}, ${monthName} ${dateNumber}${suffix}`;
}


/**
 * Determines if a given string is a valid email address.
 * 
 * @param {string} email - The email address to validate.
 * @returns {boolean} - Returns true if the email is valid, otherwise returns false.
 * 
 * @example
 * 
 * isValidEmail("example@gmail.com");  // returns true
 * isValidEmail("example.com");        // returns false
 */
export function isValidEmail(email: string): boolean {
  // Regular expression for email validation
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
}


/**
 * Determines if a given string is a valid full name.
 * 
 * @param {string} name - The name to validate.
 * @returns {boolean} - Returns true if the name is valid, otherwise returns false.
 * 
 * @example
 * 
 * isValidFullName("John Doe");  // returns true
 * isValidFullName("Jo D");      // returns true
 * isValidFullName("J Doe");     // returns false
 */
export function isValidFullName(name: string): boolean {
  // Check if name has at least two words
  const fullName = name.trim().split(' ');
  const firstName = fullName[0];
  const lastName = fullName.slice(1).join(' ') || "";

  // First name must be at least two letters
  if (firstName.length < 2) return false;

  // Last name must be at least 1 letter
  if (lastName.length < 1) return false;

  // Ensure name doesn't contain numbers or special characters
  const regex = /^[.a-zA-Z\s]*$/;
  return regex.test(name);
}

/**
 * Check if the current time is within a given time window.
 * @param startHour - Start hour of the time window (24-hour format).
 * @param endHour - End hour of the time window (24-hour format).
 * @returns Boolean indicating if the current time is within the specified window.
 */
export function isWithinTimeWindow(startHour: number, endHour: number): boolean {
  const now = new Date();
  const currentHour = now.getHours();
  if (startHour <= endHour) {
    return currentHour >= startHour && currentHour <= endHour;
  } 
  // For cases where the window spans across midnight, e.g., 8PM to 8AM
  return currentHour >= startHour || currentHour <= endHour;
}

/**
 * Generates a random 6-digit order ID prefixed by a '#'.
 * 
 * @returns {string} The generated order ID.
 * 
 * @example
 * const orderID = generateOrderID(); // e.g., #123456
 */
export function generateOrderID(): string {
  // Generate a random number between 0 and 999999
  const randomNum = Math.floor(Math.random() * 1000000);

  // Convert the number to a string and pad with leading zeros to ensure it's 6 digits
  const paddedNum = String(randomNum).padStart(6, '0');

  // Prefix with a '#'
  return `#${paddedNum}`;
}
