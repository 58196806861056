import React from "react";
const heroImage = require("../../images/moving-hero.png");

function StartAMoveButton() {
  const handleClick = () => {
    window.location.href = "/start-a-move";
  };
  return (
    <button
      onClick={handleClick}
      className="p-3 w-52 rounded-xl font-semibold text-white bg-gradient-to-r from-pink-500 to-pink-600 hover:scale-95"
    >
      Start a move
    </button>
  );
}

function HeroSection() {
  return (
    <section id="hero" className="mx-auto px-6 text-center pb-8">
      <img
        src={heroImage}
        alt="Hero Image"
        className="mx-auto w-full max-w-4xl"
      />
      <h1 className="mt-14 mb-2 text-5xl md:text-4xl font-bold leading-normal max-w-2xl mx-auto">
        Citywide delivery, simplified.
      </h1>
      <p className="mb-10 text-xl font-medium leading-normal max-w-2xl mx-auto">
      Util specializes in single large item deliveries (ex. a sofa), or small deliveries weighing under 30 lbs.
      </p>
      <p className="mb-10 text-xl font-medium leading-normal max-w-2xl mx-auto">
      Easy. Low-Cost. Friendly.
      </p>
      <StartAMoveButton />
    </section>
  );
}

export default HeroSection;
