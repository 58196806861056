import React from "react";
import { getNextDeliveryDates } from "../../../utils/helperUtils";
import { useDispatch, useSelector } from "react-redux";
import { setDeliveryDate, setLargeItemSelectedDateIndex } from "../../../slices/deliveryWindowSlice";
import { RootState } from "../../../slices";
import {
  NotSelectedCheckbox,
  SelectedCheckbox,
  InactiveCheckbox,
} from "../../shared/Checkbox";
import { DeliveryDateInfo } from "../../../slices/deliveryWindowSlice";

// Checkbox states
enum CheckboxState {
  NotSelected = 0,
  Selected = 1,
  Inactive = 2,
}

interface CheckboxButtonProps {
  index: number;
  checkboxState: CheckboxState;
  deliveryDateInfo: DeliveryDateInfo | null;
  onTimeSelected: (index: number, time: string) => void;
}

// Main checkbox button component
function CheckboxButton({
  index,
  checkboxState,
  deliveryDateInfo,
  onTimeSelected,
}: CheckboxButtonProps) {
  if (!deliveryDateInfo) {
    return null;
  }

  const daysWord = deliveryDateInfo.daysFromNow === 1 ? "day" : "days";
  
  return (
    <button
      onClick={() => onTimeSelected(index, `${deliveryDateInfo.day}, ${deliveryDateInfo.date}`)}
      className={`flex items-center w-full h-24 text-left rounded-lg  border-2  text-slate-800 transition-all duration-150 ${
        checkboxState === CheckboxState.Selected
          ? "bg-slate-200 border-black"
          : "hover:bg-slate-100 border-slate-300"
      }`}
    >
      <div className="flex items-center justify-left px-4 py-2">
        {checkboxState === CheckboxState.NotSelected && <NotSelectedCheckbox />}
        {checkboxState === CheckboxState.Selected && <SelectedCheckbox />}
        {checkboxState === CheckboxState.Inactive && <InactiveCheckbox />}
        <div className="pl-4 text-normal">
          <p className="text-left text-md font-bold text-slate-700">
            {`${deliveryDateInfo.day}, ${deliveryDateInfo.date} (${deliveryDateInfo.daysFromNow} ${daysWord} from now)`}
          </p>
          <p className="mt-2 text-left text-md font-light text-slate-700">
            Sender & receiver available between 6-11PM
          </p>
        </div>
      </div>
    </button>
  );
}

function NoneOfTheAboveButton({
  index,
  checkboxState,
  onTimeSelected,
}: CheckboxButtonProps) {

  return (
    <button
    onClick={() => onTimeSelected(index, "Large Item: None of the above selected")}

      className={`flex items-center w-full h-24 text-left rounded-lg  border-2 text-slate-800 transition-all duration-150 ${
        checkboxState
          ? "bg-slate-200 border-black"
          : "bg-white border-slate-300"
      }`}
    >
      <div className="flex items-center justify-left px-4 py-2">
        {checkboxState === CheckboxState.NotSelected && <NotSelectedCheckbox />}
        {checkboxState === CheckboxState.Selected && <SelectedCheckbox />}
        {checkboxState === CheckboxState.Inactive && <InactiveCheckbox />}

        <div className="pl-4 text-normal">
          <p className="text-left text-md font-bold text-slate-700">None of the above</p>
          <p className="mt-2 text-left text-md font-light text-slate-700">
            We’ll contact you with some questions.
          </p>
        </div>
      </div>
    </button>
  );
}

// Main component to render checkboxes with delivery dates
function OverFiftySelection() {
  const deliveryDates = getNextDeliveryDates();
  const selectedDeliveryIndex = useSelector(
    (state: RootState) => state.deliveryWindow.largeItemSelectedDateIndex
  );

  const dispatch = useDispatch();
  function onTimeSelected(index: number, timeString: string) {
    dispatch(setDeliveryDate(timeString));
    dispatch(setLargeItemSelectedDateIndex(index));
  }

  return (
    <div className="space-y-2 ">
      {deliveryDates.map((deliveryDateInfo, index) => {
        const daysWord = deliveryDateInfo.daysFromNow === 1 ? "day" : "days"; // Pluralizing 'day'
        return (
          <CheckboxButton
            checkboxState={
              selectedDeliveryIndex === index
                ? CheckboxState.Selected
                : CheckboxState.NotSelected
            }
            index={index}
            deliveryDateInfo={deliveryDateInfo}
            key={index}
            onTimeSelected={onTimeSelected}
          />
        );
      })}

      <NoneOfTheAboveButton
        checkboxState={
          selectedDeliveryIndex === -1
            ? CheckboxState.Selected
            : CheckboxState.NotSelected
        }
        index={-1}
        deliveryDateInfo={null}
        key={-1}
        onTimeSelected={onTimeSelected}
      />
    </div>
  );
}

export default OverFiftySelection;
