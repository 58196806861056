// src/components/StartMove/FloatingPageController.tsx

import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../slices";
import { allowProceed, preventProceed } from "../../slices/stepSlice";
import { isAddressComplete } from "../../slices/enterAddressSlice";
import { isSizeOfMoveComplete } from "../../slices/sizeOfMoveSlice";
import { isDeliveryWindowComplete } from "../../slices/deliveryWindowSlice";
import { isUserComplete } from "../../slices/userSlice";
import {
  setPageTo,
  getNextPage,
  getPreviousPage,
} from "../../slices/stepSlice";
import { StepPage } from "../../slices/stepSlice";

function BackButton() {
  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state);

  function handleBack() {
    const previous = getPreviousPage(state);
    dispatch(setPageTo(previous));
  }
  return (
    <button
      // className="bg-gray-200 text-gray-600 font-semibold px-12 md:px-24 py-4 rounded-xl"
      className="bg-gray-200 text-gray-600 font-semibold px-12 md:px-24 py-4 rounded-xl"
      onClick={handleBack}
    >
      Back
    </button>
  );
}
function NextButton() {
  const state = useSelector((state: RootState) => state);
  const isActive = useSelector((state: RootState) => state.step.canProceed);
  const dispatch = useDispatch();

  const activeClass =
    "bg-blue-600 text-white font-semibold px-12 md:px-24 py-4 rounded-xl";
  const inactiveClass =
    "bg-slate-500/60 text-white font-semibold px-12 md:px-24 py-4 rounded-xl";

  function handleNext() {
    const nextPage = getNextPage(state, dispatch);
    dispatch(setPageTo(nextPage));
  }
  return (
    <button
      disabled={!isActive}
      className={isActive ? activeClass : inactiveClass}
      // className="bg-slate-500/50 text-white font-semibold px-12 md:px-24 py-4 rounded-xl"
      onClick={handleNext}
    >
      Next
    </button>
  );
}

function BottomNavigationBar() {
  const dispatch = useDispatch();

  const isSignedIn = useSelector((state: RootState) => state.user.isSignedIn);
  const activeStep = useSelector((state: RootState) => state.step.activeStep);
  const sizeOfMoveState = useSelector((state: RootState) => state.sizeOfMove);
  const userState = useSelector((state: RootState) => state.user);
  const enterAddressState = useSelector(
    (state: RootState) => state.enterAddress
  );
  const deliveryWindowState = useSelector(
    (state: RootState) => state.deliveryWindow
  );

  // Check if the user can proceed based on the active step
  useEffect(() => {
    let canProceed = false;
    switch (activeStep) {
      case StepPage.Address:
        canProceed = isAddressComplete(enterAddressState);
        break;
      case StepPage.MoveSize:
        canProceed = isSizeOfMoveComplete(sizeOfMoveState);
        break;
      case StepPage.DeliveryWindow:
        canProceed = isDeliveryWindowComplete(deliveryWindowState, sizeOfMoveState);
        break;
      case StepPage.Contact:
        canProceed = isUserComplete(userState, enterAddressState);
        break;
      default:
        break;
    }
    if (canProceed) {
      dispatch(allowProceed());
    } else {
      dispatch(preventProceed());
    }
  }, [
    activeStep,
    enterAddressState,
    sizeOfMoveState,
    deliveryWindowState,
    userState,
    dispatch,
  ]); // Rerun whenever activeStep changes

  if (activeStep === 4 && !isSignedIn) {
    return null;
  }

  return (
    <div className="fixed bottom-0 left-0 w-full py-6 bg-white shadow-2xl shadow-black z-50">
      <div
        className={`flex ${
          activeStep === 0
            ? "justify-end md:justify-center"
            : "justify-between md:justify-center"
        } px-8 md:space-x-24`}
      >
        {activeStep !== 0 && <BackButton />}
        {activeStep < 5 ? <NextButton /> : null}
      </div>
    </div>
  );
}

export default BottomNavigationBar;
