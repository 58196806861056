import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";

interface AccordionTitleInterface {
  children: any;
}
interface AccordionContentInterface {
  children: any;
}

function AccordionTitle({ children }: AccordionTitleInterface) {
  return (
    <Typography variant="h6" component="div" sx={styles.accordionTitleStyles}>
      {children}
    </Typography>
  );
}

function AccordionContent({ children }: AccordionContentInterface) {
  return (
    <Typography variant="body1" component="div">
      {children}
    </Typography>
  );
}

function AccordionWhereOperate() {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <AccordionTitle>
        <p className="text-l font-medium leading-normal max-w-2xl mx-auto">
          Where do we operate?
        </p>
        </AccordionTitle>
      </AccordionSummary>
      <AccordionDetails>
        <AccordionContent>
          <p>Util is an intra-city delivery service. This means that 
          we only serve requests that start and end in the same city.</p>
          <br></br>
          <p>We are currently serving New York City's Manhattan borough. We
          plan to launch the remaining boroughs in New York City, as well as a
          number of other key US cities, as soon as possible.</p>
        </AccordionContent>
      </AccordionDetails>
    </Accordion>
  );
}

function AccordionHowUnder30() {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <AccordionTitle>
        <p className="text-l font-medium leading-normal max-w-2xl mx-auto">
          How It Works: Deliveries Under 30 lbs
        </p>
        </AccordionTitle>
      </AccordionSummary>
      <AccordionDetails>
        <Box>
          {/* <Typography variant="h6" gutterBottom>
            Deliver under 30 lbs.
          </Typography> */}
          <Typography variant="body1" paragraph>
            <b>Size & Quantity</b>
            <p>We specialize in either small deliveries under 30 lbs (ex. a lamp or a small 
              bag of a few items which, in total, weigh less than 30 lbs), or deliveries 
              of single large items (ex. a sofa, table and chairs, or an armchair). 
            </p>
          </Typography>
          <Typography variant="body1" paragraph>
            <b>When</b>
            <p>We currently fulfill same-day deliveries with options for your
              delivery to be completed within the next 1-3 hours or later that evening.</p>
              <br></br>
            <p>Requests are completed between 8AM and 11PM.</p>
            <br></br>
            <p>Util assumes that both you and the other party 
              will be free during this time.</p>
          </Typography>
          <Typography variant="body1" paragraph>
            <b>Experience</b>
            <p>An approved Util mover arrives at the sender's home to pick up
            item for delivery, and securely transports it to the receiver. Simple! 
            </p>
            <br></br>
            <p>We also provide escrow services between buyers and sellers, if needed.</p>
          </Typography>
          {/* <Typography variant="body1" paragraph>
            <b>Cost:</b>
            Flat rate $X in same borough (not sure if we should include cost
            here or not)
          </Typography> */}
          <br></br>
          <Typography variant="caption" paragraph>
          *Please make sure your package is under 30 pounds, securely sealed, and not a prohibited item (such as alcohol, medication, recreational drugs, and dangerous or illegal items).
          </Typography>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}

function AccordionHowOver30() {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <AccordionTitle>
        <p className="text-l font-medium leading-normal max-w-2xl mx-auto">
          How It Works: Deliveries Over 30 lbs
        </p>
        </AccordionTitle>
      </AccordionSummary>
      <AccordionDetails>
        <Box>
          {/* <Typography variant="h6" gutterBottom>
            Deliver over 30 lbs.
          </Typography> */}
          <Typography variant="body1" paragraph>
            <b>Size & Quantity</b>
            <p>We specialize in delivering either single large items (ex. a sofa, table 
              and chairs, or an armchair), or small deliveries under 30 lbs (ex. a lamp, or a small 
                bag of a few items which, in total, weigh less than 30 lbs).
            </p>
          </Typography>
          <Typography variant="body1" paragraph>
            <b>When</b>
            <p>Our deliveries for items over 30 lbs take place 
              between 6PM and 11PM on weekday evenings.</p>
            <br></br>
            <p>Pending availability, you can make a delivery reservation 
            for one of the available evenings that week (not same-day). More
              weekdays will become available every day.</p>
            <br></br>
            <p>Util assumes that both you and the other party 
              will be free during this time.</p>
          </Typography>
          <Typography variant="body1" paragraph>
            <b>Experience</b>
            <p>Two approved Util movers arrive at the sender's home, wrap the
            item if necessary, secure it in our van or truck, and bring it to the 
            recipient. Simple!
            </p>
            <br></br>
            <p>We also provide escrow services between buyers and sellers, if needed.
            </p>
          </Typography>
          <br></br>
          <Typography variant="caption" paragraph>
          *Please make sure your item is not a prohibited item (such as alcohol, medication, recreational drugs, and dangerous or illegal items).
          </Typography>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}

function AccordionEscrow() {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <AccordionTitle>
        <p className="text-l font-medium leading-normal max-w-2xl mx-auto">
        How else does Util help people buying and selling used items?
        </p>
        </AccordionTitle>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>
          <p>Util provides an optional escrow service for buyers and sellers.</p>
          <br></br>
          <p>This means that, if preferred, Util can charge the buyer for the cost of
          the item (before it is picked up from the seller) and will hold this
          payment until the item is delivered. At which point, the seller will
          receive the payment.</p>
          <br></br>
          <p>This also helps the buyer in that their payment to the seller is not released
            until the item is delivered.</p>
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
}

function HowItWorksAccordion() {
  return (
    <div className="flex flex-col items-center mx-auto w-full mb-52 mt-4" style={styles.container}>
      <div style={styles.innerContainer}>
        <AccordionWhereOperate />
        <AccordionHowUnder30 />
        <AccordionHowOver30 />
        <AccordionEscrow />
      </div>
    </div>
  );
}

const styles: any = {
  container: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  innerContainer: {
    width: "85%",
  },
  accordionTitleStyles: {
    fontSize: 20,
    fontWeight: 600,
    fontFamily: "Arial",
    color: "#333",
  },
};

export default HowItWorksAccordion;
