// SizeOfMove.txs
import React from "react";
import TextField from "@mui/material/TextField";
import { SizeOfMove } from "../../slices/sizeOfMoveSlice";
import { RootState } from "../../slices/index";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  setItemDescription,
  setSizeOfMove,
} from "../../slices/sizeOfMoveSlice";
import Typography from "@mui/material/Typography";

const COUCH_ICON = require("../../images/svg/couch.svg").default;
const WATCH_ICON = require("../../images/svg/watch.svg").default;
const HEADSET_ICON = require("../../images/svg/headset.svg").default;

interface MoveButtonProps {
  type: SizeOfMove;
  label: string;
  icon: string;
  price: string;
  selected: boolean;
  onSelect: () => void;
}

function MoveSizeButton({
  label,
  icon,
  price,
  selected,
  onSelect,
}: MoveButtonProps) {
  return (
    <button
      onClick={onSelect}
      className={`w-full h-22 rounded-lg border border-slate-700 text-slate-700 
            ${selected ? "bg-slate-200 border-2" : "hover:bg-slate-100 "} 
            transition-all duration-150`}
    >
      <div className="flex items-center justify-between px-4 py-2">
        <div>
          <div className="text-left text-md font-bold">{label}</div>
          <div className="mt-2 text-left text-md font-light">{price}</div>
        </div>
        <img
          className={`transform ${selected ? "scale-110" : ""} 
            transition-transform duration-150 h-10 w-10`}
          src={icon}
          alt={`${label} Icon`}
        />
      </div>
    </button>
  );
}

function ItemSizeSelection() {
  const dispatch = useDispatch();
  const selectedSizeOfMove = useSelector(
    (state: RootState) => state.sizeOfMove.sizeOfMove
  );

  const items = [
    {
      type: SizeOfMove.Small,
      label: "Small Item(s)",
      icon: WATCH_ICON,
      price: "$29 | under 30 lbs",
    },
    {
      type: SizeOfMove.Large,
      label: "Single Large Item",
      icon: COUCH_ICON,
      price: "$89 | over 30 lbs",
    },
    {
      type: SizeOfMove.Other,
      label: "Custom Delivery Size",
      icon: HEADSET_ICON,
      price: "We’ll do our best. Describe your item below.",
    },
  ];

  return (
    <div className="space-y-2">
      {items.map((item) => (
        <MoveSizeButton
          type={item.type}
          key={item.label}
          label={item.label}
          icon={item.icon}
          price={item.price}
          selected={selectedSizeOfMove === item.type}
          onSelect={() => dispatch(setSizeOfMove(item.type))}
        />
      ))}
    </div>
  );
}

function Header() {
  return (
    <div className="py-5">
      <h1 className="text-xl font-semibold mb-2 text-slate-700">
        What size is your delivery?
      </h1>
      <p className="text-slate-600 text-sm">
        We specialize in delivering either a single large item or set (such as a
        sofa or table and chairs) or a few small items totalling under 30 lbs
        (ex. a secondhand lamp, or a bag of a few small things).
      </p>
    </div>
  );
}

function DescriptionHeader() {
  return (
    <div className="py-5 mt-4">
      <h1 className="text-lg font-semibold text-slate-700">
        Describe the item you need delivered.
      </h1>
    </div>
  );
}

function DescriptionTextField() {
  const itemDescription = useSelector(
    (state: RootState) => state.sizeOfMove.itemDescription
  );

  const dispatch = useDispatch();
  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    dispatch(setItemDescription(event.target.value));
  }
  return (
    <div>
      <TextField
        multiline
        rows={3}
        className="w-full mb-3 bg-white border border-gray-200 rounded-md"
        placeholder="ex. Need help moving a neon green armchair! Kind of heavy."
        variant="filled"
        value={itemDescription}
        onChange={handleChange}
      />
    </div>
  );
}

function MoveSizeScreen() {
  return (
    <div
      className="flex flex-col h-screen mx-auto mb-20
    md:p-8 md:px-12 md:pb-56 md:space-y-8 md:border md:max-w-4xl md:rounded-xl md:shadow-xl md:mt-10 md:mb-52
    "
    >
      <Header />
      <ItemSizeSelection />
      <DescriptionHeader />
      <DescriptionTextField />
      <Typography variant="caption" paragraph className="pt-4">
        Reminder: Your delivery cannot include any prohibited items (such as
        alcohol, medication, recreational drugs, and dangerous or illegal
        items).
      </Typography>
    </div>
  );
}

export default MoveSizeScreen;
