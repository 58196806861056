import React from "react";
import HeroSection from "../components/HomePage/HeroSection"
import Header from "../components/HomePage/Header";
import HowItWorksAccordion from "../components/HomePage/HowItWorksAccordion"

function HomePage() {
  return (
    <div>
      <Header />
      <HeroSection />
      <HowItWorksAccordion />
    </div>
  );
}

export default HomePage;
