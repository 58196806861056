// src/slices/sizeOfMoveSlice.ts

import { createSlice } from "@reduxjs/toolkit";
import { RootState } from ".";

export enum SizeOfMove {
  Small = "small",
  Large = "large",
  Other = "other",
}

export interface SizeOfMoveState {
  sizeOfMove: SizeOfMove;
  itemDescription: string;
}

const initialState = {
  sizeOfMove: null,
  itemDescription: "",
};

export const sizeOfMoveSlice = createSlice({
  name: "sizeOfMove",
  initialState: initialState,
  reducers: {
    setSizeOfMove: (state, action) => {
      state.sizeOfMove = action.payload;
    },
    setItemDescription: (state, action) => {
      state.itemDescription = action.payload;
    },
  },
});

export const isSizeOfMoveComplete = (moveState: SizeOfMoveState): boolean => {
  // One size option must be selected (cannot be null) and item description must be at least 4 characters long.
  const isMoveSelected = moveState.sizeOfMove !== null;
  const itemDescriptionValid = moveState.itemDescription.length >= 4;

  return isMoveSelected && itemDescriptionValid;
};

export function shouldRedirectSizeOfMove(state: RootState): boolean {
  return state.sizeOfMove.sizeOfMove === SizeOfMove.Other;
}

export const { setSizeOfMove, setItemDescription } = sizeOfMoveSlice.actions;
export default sizeOfMoveSlice.reducer;
