import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../slices";
import {
  sendVerificationCode,
  confirmVerificationCode,
  CAPTCHA_CONTAINER_ID,
  CAPTCHA_CONTAINER_PARENT_ID,
} from "../../services/firebaseService";
import { setVerificationCodeSent } from "../../slices/userSlice";
import { setIsSignedIn } from "../../slices/userSlice";
import {
  setPageTo,
  getNextPage,
  getPreviousPage,
} from "../../slices/stepSlice";

let localConfirmationResult: any = null;

function Header() {
  return (
    <h1 className="text-xl font-semibold mb-2 text-slate-700">
      Verify your phone number
    </h1>
  );
}

function Subtitle() {
  const phoneNumber = useSelector((state: RootState) => state.user.phoneNumber);
  return (
    <p className="mb-2 text-md text-slate-700">
      {`Enter the 6 digit code sent to --- --- --${phoneNumber.slice(-2)}.`}
    </p>
  );
}

function VerificationTextField({
  code,
  setCode,
}: {
  code: string;
  setCode: (value: string) => void;
}) {
  return (
    <TextField
      autoFocus
      margin="dense"
      label="Verification Code"
      placeholder="123456"
      inputProps={{ maxLength: 6 }}
      type="tel"
      fullWidth
      value={code}
      onChange={(e) => setCode(e.target.value)}
    />
  );
}

function ConfirmButton({ code }: { code: string }) {
  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state);

  const activeClass = "py-4 w-40 bg-blue-600 text-white font-bold rounded-xl";
  const inactiveClass =
    "py-4 w-40 bg-slate-500 text-white font-bold rounded-xl";

  function increment() {
    const nextPage = getNextPage(state, dispatch);
    dispatch(setPageTo(nextPage));
  }
  function hasSixDigits(): boolean {
    return code.length === 6;
  }

  async function confirmCode() {
    const success = await confirmVerificationCode(
      localConfirmationResult as any,
      code
    );

    if (success) {
      increment();
      dispatch(setIsSignedIn(true));
    }
  }

  return (
    <button
      disabled={!hasSixDigits()}
      onClick={confirmCode}
      className={!hasSixDigits() ? inactiveClass : activeClass}
    >
      Next
    </button>
  );
}
function BackButton() {
  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state);

  function decrement() {
    const previous = getPreviousPage(state);
    dispatch(setPageTo(previous));
  }
  return (
    <button
      onClick={decrement}
      className="py-4  w-40 bg-clear border-2 border-black text-black font-bold rounded-xl"
    >
      Back
    </button>
  );
}

function ResendCodeButton() {
  const phoneNumber = useSelector((state: RootState) => state.user.phoneNumber);

  async function resendCode() {
    console.log("resendCode");
    console.log(`${phoneNumber}`);
    localConfirmationResult = await sendVerificationCode(phoneNumber);
  }
  return (
    <button onClick={resendCode} className="flex justify-end text-slate-500">
      Need to resend the code?
    </button>
  );
}

function NavigationButtons({ code }: { code: string }) {
  return (
    <div className="relative">
      <div className="absolute top-0 left-0" id={CAPTCHA_CONTAINER_ID}></div> 
      <div id={CAPTCHA_CONTAINER_PARENT_ID} className="flex justify-between">
        <BackButton />
        <ConfirmButton code={code} />
      </div>
    </div>
  );
}

function PhoneVerification() {
  const [code, setCode] = useState("");
  const phoneNumber = useSelector((state: RootState) => state.user.phoneNumber);
  const verificationCodeSent = useSelector(
    (state: RootState) => state.user.verificationCodeSent
  );

  useEffect(() => {
    async function sendVerificationCodeOnAppear() {
      // If verification code has not been sent, send it
      if (phoneNumber && !verificationCodeSent) {
        localConfirmationResult = await sendVerificationCode(phoneNumber);

        // Set verification code sent to true [UNUSED]
        setVerificationCodeSent(true);
      }
    }

    sendVerificationCodeOnAppear();
  });

  return (
    <div
      className="space-y-4 mt-12 mx-auto
    md:p-8 md:px-12 md:pb-56 md:space-y-8 md:border md:max-w-4xl md:rounded-xl md:shadow-xl md:mt-10 md:mb-52"
    >
      <Header />
      <Subtitle />
      <ResendCodeButton />
      <VerificationTextField code={code} setCode={setCode} />
      <NavigationButtons code={code} />
    </div>
  );
}

export default PhoneVerification;
