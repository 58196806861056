import React from "react";
import HomePage from "./screens/HomePage";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import StartMove from "./screens/StartMove";

import { Provider } from "react-redux";
import store from "./store";

import ConfirmationScreen from "./components/StartMove/ConfirmationScreen";


function App() {
  return (
    <Provider store={store}>
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/start-a-move" element={<StartMove />} />
          <Route path="/confirmation" element={<ConfirmationScreen />} />
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
