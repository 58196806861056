// src/slices/index.ts

import { combineReducers } from '@reduxjs/toolkit';
import stepReducer, { StepState } from './stepSlice';
import sizeOfMoveReducer, { SizeOfMoveState } from './sizeOfMoveSlice';
import deliveryWindowReducer, { DeliveryWindowState } from './deliveryWindowSlice';
import userSlice, { UserState } from './userSlice';
import enterAddressSlice, { EnterAddressState } from './enterAddressSlice';

const rootReducer = combineReducers({
  step: stepReducer,
  sizeOfMove: sizeOfMoveReducer,
  deliveryWindow: deliveryWindowReducer,
  user: userSlice,
  enterAddress: enterAddressSlice,
});

export type RootState = {
  step: StepState;
  sizeOfMove: SizeOfMoveState;
  deliveryWindow: DeliveryWindowState;
  user: UserState;
  enterAddress: EnterAddressState;
}

export default rootReducer;
