// SizeOfMove.txs
import React from "react";
// import SpeedIcon from "@mui/icons-material/Speed";
import ElectricBoltSharpIcon from "@mui/icons-material/ElectricBoltSharp";
import WbTwilightIcon from "@mui/icons-material/WbTwilight";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";
import {
  setSmallItemDeliveryWindow,
  setDeliveryDate,
  setPreferredDeliveryTime,
  SmallItemTimeSelection,
} from "../../../slices/deliveryWindowSlice";
import { RootState } from "../../../slices";
import {
  getFormattedTodayDate,
  isWithinTimeWindow,
} from "../../../utils/helperUtils";

interface DeliveryTimeButtonProps {
  timeType: string;
  selectedTime: string | null;
  onTimeSelected: (time: string) => void;
}

// Individual delivery time button component
function DeliveryTimeButton({
  timeType,
  selectedTime,
  onTimeSelected,
}: DeliveryTimeButtonProps) {
  // These could potentially be moved outside of the component for optimization
  function getIcon() {
    switch (timeType) {
      case SmallItemTimeSelection.Soon:
        return <ElectricBoltSharpIcon />;
      case SmallItemTimeSelection.Evening:
        return <WbTwilightIcon />;
      case SmallItemTimeSelection.Other:
        return <CalendarMonthIcon />;
      default:
        return null;
    }
  }

  function getTitle() {
    switch (timeType) {
      case SmallItemTimeSelection.Soon:
        return "Soon";
      case SmallItemTimeSelection.Evening:
        return "This Evening";
      case SmallItemTimeSelection.Other:
        return "Custom Time Window";
      default:
        return null;
    }
  }

  function getSubtitle() {
    switch (timeType) {
      case SmallItemTimeSelection.Soon:
        return "Sender & receiver available within next 1-3 hrs";
      case SmallItemTimeSelection.Evening:
        return "Sender & receiver available between 6-11 PM";
      case SmallItemTimeSelection.Other:
        return "We'll do our best to help! Provide details below.";
      default:
        return null;
    }
  }

  return (
    <button
      onClick={() => onTimeSelected(timeType)}
      className={`flex items-center w-full h-24 text-left rounded-lg border border-slate-900 text-slate-800 
          ${
            selectedTime === timeType
              ? "bg-slate-200 border-2"
              : "hover:bg-slate-100"
          } 
          transition-all duration-150`}
    >
      <div className="flex items-center justify-left px-4 py-2">
        {getIcon()}
        <div className="pl-4 text-normal">
          <p className="text-left text-md font-bold text-slate-700">
            {getTitle()}
          </p>
          <p className="mt-2 text-left text-md font-light text-slate-700">
            {getSubtitle()}
          </p>
        </div>
      </div>
    </button>
  );
}

function OptionalEnterAvailableTime({
  selectedTime,
}: {
  selectedTime: string | null;
}) {
  const dispatch = useDispatch();
  const preferredDeliveryTime = useSelector(
    (state: RootState) => state.deliveryWindow.smallItemDeliveryDetails
  );
  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    dispatch(setPreferredDeliveryTime(event.target.value));
  }
  return (
    <div className="py-5 mt-4">
      {selectedTime === SmallItemTimeSelection.Other && (
        <div className="mt-4 space-y-2">
          <h1 className="text-xl font-semibold mb-2 text-slate-700 ">
            Preferred delivery time?
          </h1>
          <p className="text-slate-600 text-sm">
            Please provide 2-3 preferred delivery dates and times.
          </p>
          <TextField
            multiline
            fullWidth
            rows={3}
            value={preferredDeliveryTime}
            variant="outlined"
            placeholder="Thursday Oct 26th between 2-5PM or Monday Nov 6th between 10AM-1PM"
            onChange={handleChange}
          />
        </div>
      )}
    </div>
  );
}

function UnderFiftySelection() {
  const dispatch = useDispatch();
  const smallItemDeliveryWindow = useSelector(
    (state: RootState) => state.deliveryWindow.smallItemDeliveryWindow
  );

  function updateSelection(time: string) {
    dispatch(setSmallItemDeliveryWindow(time));
    const todayDate = getFormattedTodayDate();

    // If the user selects "This evening", we automatically save the current day
    // as the delivery date
    if (time === SmallItemTimeSelection.Soon) {
      dispatch(setDeliveryDate(`Next 1-3 hrs! 🔥 - ${todayDate}`));
      return;
    } else if (time === SmallItemTimeSelection.Evening) {
      dispatch(setDeliveryDate(todayDate));
      return;
    } else {
      dispatch(setDeliveryDate("Small Item: Custom Time Window Selected"));
    }
  }

  return (
    <div className="space-y-2">
      {isWithinTimeWindow(8, 17) && (
        <DeliveryTimeButton
          timeType={SmallItemTimeSelection.Soon}
          selectedTime={smallItemDeliveryWindow}
          onTimeSelected={updateSelection}
        />
      )}

      {isWithinTimeWindow(5, 20) && (
        <DeliveryTimeButton
          timeType={SmallItemTimeSelection.Evening}
          selectedTime={smallItemDeliveryWindow}
          onTimeSelected={updateSelection}
        />
      )}

      <DeliveryTimeButton
        timeType={SmallItemTimeSelection.Other}
        selectedTime={smallItemDeliveryWindow}
        onTimeSelected={updateSelection}
      />

      <OptionalEnterAvailableTime selectedTime={smallItemDeliveryWindow} />
    </div>
  );
}

export default UnderFiftySelection;
