// src/components/StartMove/AlternativeScreens/RedirectScreen.tsx
import { useState } from "react";
import PhoneIcon from "@mui/icons-material/Phone";
import { MuiTelInput } from "mui-tel-input";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../slices";
import { setPhone } from "../../slices/userSlice";
import { sendEmailToBackend } from "../../services/emailService";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { DeliveryRole, setDeliveryRole } from "../../slices/enterAddressSlice";

export enum RedirectScreenType {
  Address,
  MoveSize,
  DeliveryWindow,
}

interface RedirectScreenProps {
  redirectType: RedirectScreenType;
}

interface RedirectScreenData {
  header: string;
  mainContent: string;
  buttonLabel: string;
  icon: any;
}

const redirectScreenData: Record<
  RedirectScreenType,
  {
    header: string;
    mainContent: string;
    buttonLabel: string;
    emailHeader: string;
    icon: any;
  }
> = {
  [RedirectScreenType.Address]: {
    header: "We'll let you know when we launch in your area!",
    mainContent:
      "At this time, Util only supports pick-ups and drop-offs within the same city, and is currently only operating in the borough of Manhattan in New York City.",
    buttonLabel: "Let me know!",
    emailHeader: "Address Not Supported",
    icon: <PhoneIcon sx={{ fontSize: 50 }} style={{ color: "#18A558" }} />,
  },
  [RedirectScreenType.MoveSize]: {
    header: "We'll do our best to help!",
    mainContent:
      "While Util currently best supports deliveries of either a single large item (such as an armchair) or a few small items, we will do our best to fulfill a custom request.",
    buttonLabel: "Request Support From Util",
    emailHeader: "Custom Delivery Size Requested",
    icon: <PhoneIcon sx={{ fontSize: 50 }} style={{ color: "#18A558" }} />,
  },
  [RedirectScreenType.DeliveryWindow]: {
    header: "We'll do our best to help!",
    mainContent:
      "While Util aims to provide our customers with cheaper delivery options by optimizing our pickup and drop-off days and times, we will do our best to fulfill a custom request.",
    buttonLabel: "Request Support From Util",
    emailHeader: "Custom Delivery Time Window Selected",
    icon: <PhoneIcon sx={{ fontSize: 50 }} style={{ color: "#18A558" }} />,
  },
};

function SendingOrReceiving({
  redirectType,
}: {
  redirectType: RedirectScreenType;
}) {
  const dispatch = useDispatch();
  const deliveryRole = useSelector(
    (state: RootState) => state.enterAddress.deliveryRole
  );

  const handleOptionChange = (e: SelectChangeEvent<string | null>) => {
    dispatch(setDeliveryRole(e.target.value));
  };

  if (redirectType === RedirectScreenType.Address) {
    return null;
  }
  return (
    <div className="mt-5 flex flex-col space-y-2">
      <p className="mb-2 text-xl font-medium text-slate-700">
        Are you the sender or receiver?
      </p>
      <div className="flex flex-col">
        <FormControl fullWidth>
          <InputLabel id="deliveryRole-label">Select an option</InputLabel>
          <Select
            labelId="deliveryRole-label"
            value={deliveryRole || ""}
            onChange={handleOptionChange}
            label="Select an option"
          >
            <MenuItem value={DeliveryRole.Receiver}>I am the receiver</MenuItem>
            <MenuItem value={DeliveryRole.Sender}>I am the sender</MenuItem>
          </Select>
        </FormControl>
      </div>
    </div>
  );
}

function StatusMessage({ message }: { message: string | null }) {
  return (
    <div>
      {message && (
        <div className="mt-4 text-center">
          <p
            className={
              message.includes("successfully")
                ? "text-green-700 font-bold"
                : "text-red-500 font-bold"
            }
          >
            {message}
          </p>
        </div>
      )}
    </div>
  );
}

function PhoneInput({
  value,
  onChange,
}: {
  value: string;
  onChange: (newValue: string) => void;
}) {
  return (
    <div className="flex flex-col mt-2">
      <MuiTelInput value={value} onChange={onChange} defaultCountry={"US"} />
      <Typography variant="caption" paragraph className="text-slate-600">
        By providing us with your mobile number, you agree to receive text
        messages to this number from Util.
      </Typography>
    </div>
  );
}

function Header({ redirectData }: { redirectData: RedirectScreenData }) {
  return (
    <div className="flex flex-col space-y-2">
      <div className="flex justify-center">
        <PhoneIcon sx={{ fontSize: 50 }} style={{ color: "#18A558" }} />
      </div>
      <p className="mt-8 font-semibold text-xl text-center text-slate-700">
        {redirectData.header}
      </p>
      <p className="mt-8 text-md text-slate-700 text-center">
        {redirectData.mainContent}
      </p>
    </div>
  );
}

function PhoneNumberSection({
  value,
  onChange,
}: {
  value: string;
  onChange: (newValue: string) => void;
}) {
  return (
    <div className="flex flex-col space-y-2">
      <p className="mt-8 text-xl text-slate-700">What is your mobile number?</p>
      <PhoneInput value={value} onChange={onChange} />
    </div>
  );
}

function SubmitButton({
  buttonLabel,
  disabled,
  onClick,
}: {
  buttonLabel: string;
  disabled: boolean;
  onClick: () => void;
}) {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={`mt-3 rounded-lg py-2 px-4 text-white ${
        disabled ? "bg-gray-400" : "bg-blue-600 hover:bg-blue-700"
      }`}
    >
      {buttonLabel}
    </button>
  );
}

export default function RedirectScreen({ redirectType }: RedirectScreenProps) {
  const dispatch = useDispatch();
  const phoneNumber = useSelector((state: RootState) => state.user.phoneNumber);
  const userState = useSelector((state: RootState) => state.user);
  const deliveryWindow = useSelector(
    (state: RootState) => state.deliveryWindow
  );
  const enterAddress = useSelector((state: RootState) => state.enterAddress);
  const sizeOfMove = useSelector((state: RootState) => state.sizeOfMove);
  const isButtonDisabled = phoneNumber.length < 15;

  const [message, setMessage] = useState<string | null>(null);

  const redirectData = redirectScreenData[redirectType];

  function handlePhoneNumberChange(newValue: string) {
    dispatch(setPhone(newValue));
  }

  async function funcSendEmail() {
    await sendEmailToBackend(
      userState,
      deliveryWindow,
      enterAddress,
      sizeOfMove,
      redirectData.emailHeader
    )
      .then((res) => {
        setMessage("Message sent successfully!");
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
        setMessage("Failed to submit request. Please try again.");
      });
  }

  return (
    <div
      className="items-left h-screen max-w-lg pt-8 px-4 mb-10 mx-auto
    md:p-8 md:px-12 md:pb-56 md:space-y-8 md:border md:max-w-4xl md:rounded-xl md:shadow-xl md:mt-10 md:mb-52
    "
    >
      <Header redirectData={redirectData} />
      <StatusMessage message={message} />
      <PhoneNumberSection
        value={phoneNumber}
        onChange={handlePhoneNumberChange}
      />
      <SendingOrReceiving redirectType={redirectType} />
      <SubmitButton
        disabled={isButtonDisabled}
        onClick={funcSendEmail}
        buttonLabel={redirectData.buttonLabel}
      />
    </div>
  );
}
