// src/slices/timeWindowSlice.ts
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from ".";
import { SizeOfMove, SizeOfMoveState } from "./sizeOfMoveSlice";

export const SmallItemTimeSelection = {
  Soon: "soon",
  Evening: "evening",
  Other: "other",
};

export type DeliveryDateInfo = {
  day: string;
  date: string;
  daysFromNow: number;
};

export interface DeliveryWindowState {
  // Delivery options for moves under 30lbs
  smallItemDeliveryWindow:
    | (typeof SmallItemTimeSelection)[keyof typeof SmallItemTimeSelection]
    | null;
  // Under50: User's free form response preferred delivery time
  smallItemDeliveryDetails: string;
  // Delivery object for moves over 30lbs
  largeItemDateInfo: DeliveryDateInfo | null;
  // Index of the selected date in the overFiftyDateInfo array
  largeItemSelectedDateIndex: number | null;
  // Large & Small: String representation of the delivery date (e.g. "Tuesday Oct, 21st")
  selectedDeliveryDate: string;
}

const initialState: DeliveryWindowState = {
  smallItemDeliveryWindow: null,
  smallItemDeliveryDetails: "",
  largeItemDateInfo: null,
  largeItemSelectedDateIndex: null,
  selectedDeliveryDate: "",
};

export const deliveryWindowSlice = createSlice({
  name: "timeWindow",
  initialState: initialState,
  reducers: {
    setSmallItemDeliveryWindow: (state, action) => {
      state.smallItemDeliveryWindow = action.payload;

      // Reset large item path
      state.largeItemDateInfo = null;
      state.largeItemSelectedDateIndex = null;
      state.selectedDeliveryDate = "";
    },
    setLargeItemDateInfo: (state, action) => {
      state.largeItemDateInfo = action.payload;
    },
    setLargeItemSelectedDateIndex: (state, action) => {
      state.largeItemSelectedDateIndex = action.payload;

      // Reset small item path
      state.smallItemDeliveryWindow = null;
      state.smallItemDeliveryDetails = "";
    },
    setDeliveryDate: (state, action) => {
      console.log("setDeliveryDate", action.payload)
      state.selectedDeliveryDate = action.payload;
    },
    setPreferredDeliveryTime: (state, action) => {
      state.smallItemDeliveryDetails = action.payload;
    },
  },
});

export const isDeliveryWindowComplete = (deliveryWindowState: DeliveryWindowState, moveState: SizeOfMoveState): boolean => {

  if (moveState.sizeOfMove === SizeOfMove.Large) {
    // One date must be selected.
    return deliveryWindowState.largeItemSelectedDateIndex !== null;
  } else {
    // One size option must be selected
    const isUnderFiftySelected = deliveryWindowState.smallItemDeliveryWindow !== null;

    // If "Other" is chosen, check if preferredDeliveryTime field is non-empty.
    const otherSelectionIsValid =
      deliveryWindowState.smallItemDeliveryWindow !== SmallItemTimeSelection.Other ||
      deliveryWindowState.smallItemDeliveryDetails !== "";

    return isUnderFiftySelected && otherSelectionIsValid;
  }
};

export function shouldRedirectDeliveryWindow(state: RootState): boolean {
  const under50CustomerDelivery =
    state.deliveryWindow.smallItemDeliveryWindow ===
    SmallItemTimeSelection.Other;
  const over50CustomDelivery =
    !state.deliveryWindow.smallItemDeliveryWindow &&
    state.deliveryWindow.largeItemSelectedDateIndex === -1;

  return under50CustomerDelivery || over50CustomDelivery;
}

export const {
  setSmallItemDeliveryWindow,
  setLargeItemDateInfo,
  setLargeItemSelectedDateIndex,
  setDeliveryDate,
  setPreferredDeliveryTime,
} = deliveryWindowSlice.actions;
export default deliveryWindowSlice.reducer;
