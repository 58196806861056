// src/slices/userSlice.ts

import { createSlice } from "@reduxjs/toolkit";
import { generateOrderID } from "../utils/helperUtils";
import { EnterAddressState } from "./enterAddressSlice";
export interface UserState {
  fullName: string;
  email: string;
  phoneNumber: string;
  verificationCodeSent: boolean;
  isSignedIn?: boolean;
  orderID: string;
}

const initialState: UserState = {
  fullName: "",
  email: "",
  phoneNumber: "",
  verificationCodeSent: false,
  isSignedIn: false,
  orderID: generateOrderID(),
};

export const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    setFullName: (state, action) => {
      state.fullName = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setPhone: (state, action) => {
      state.phoneNumber = action.payload;
    },
    setVerificationCodeSent: (state, action) => {
      state.verificationCodeSent = action.payload;
    },
    setIsSignedIn: (state, action) => {
      state.isSignedIn = action.payload;
    },
  },
});

export const isUserComplete = (userState: UserState, enterAddressState: EnterAddressState): boolean => {
  // Check if the firstName, lastName, email, and phone fields are non-empty.
  return (
    userState.fullName !== "" &&
    userState.email !== "" &&
    userState.phoneNumber !== "" &&
    enterAddressState.deliveryRole !== null
  );
};

export const {
  setFullName,
  setEmail,
  setPhone,
  setVerificationCodeSent,
  setIsSignedIn,
} = userSlice.actions;
export default userSlice.reducer;
