// src/components/StartMove/AddressScreen.tsx

import React, { useRef, useEffect } from "react";

import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../slices";
import {
  setPickupAddress,
  setPickupSublocality,
  setRequestPickupAddress,
  setDropOffAddress,
  setDropOffSublocality,
  setRequestDropOffAddress,
} from "../../slices/enterAddressSlice";
import { NotSelectedCheckbox, SelectedCheckbox } from "../shared/Checkbox";
import { AnyAction } from "redux";
import Typography from "@mui/material/Typography";

interface AddressProps {
  addressRef: string;
  sublocalityRef: string;
  setAddress: (address: string) => AnyAction;
  setSublocality: (sublocality: string) => AnyAction;
  disabled?: boolean;
  placeholder?: string;
}

function Header() {
  return (
    <div className="pt-5">
      <h1 className="text-slate-700 text-xl font-semibold mb-2">
        Where is pickup and drop-off?
      </h1>
      <p className="text-slate-600 text-sm">
        Please provide both the pick up and drop-off addresses for your
        delivery. If you prefer, Util can also contact the other party to
        securely request their address.
      </p>
    </div>
  );
}

function PickupLocation() {
  const dispatch = useDispatch();
  const pickupAddress = useSelector(
    (state: RootState) => state.enterAddress.pickupAddress
  );
  const pickupSublocality = useSelector(
    (state: RootState) => state.enterAddress.pickupSublocality
  );
  const requestPickupAddress = useSelector(
    (state: RootState) => state.enterAddress.requestPickupAddress
  );

  const toggleSelected = () => {
    dispatch(setRequestPickupAddress(!requestPickupAddress));
  };

  const containerStyles = requestPickupAddress
    ? "w-full h-22 space-y-4 p-6 py-4 rounded-lg border-2 border-slate-400/50 text-slate-800 bg-gray-100 cursor-not-allowed"
    : "w-full h-22 space-y-4 p-6 py-4 rounded-lg border-2 border-black text-slate-800 transition-all duration-150";

  return (
    <div className={containerStyles}>
      <p
        className={`text-slate-700 text-md font-bold ${
          requestPickupAddress ? "text-gray-400" : ""
        }`}
      >
        Pickup Address
      </p>
      <Address
        addressRef={pickupAddress}
        sublocalityRef={pickupSublocality}
        setAddress={setPickupAddress}
        setSublocality={setPickupSublocality}
        disabled={requestPickupAddress}
        placeholder="Enter address"
      />
      <button onClick={toggleSelected}>
        <div className="flex space-x-4 pt-2">
          {requestPickupAddress ? (
            <SelectedCheckbox />
          ) : (
            <NotSelectedCheckbox />
          )}
          <p className="text-left text-md">
            I need Util to securely request the{" "}
            <span className="text-blue-600">
              <b>sender's</b>
            </span>{" "}
            address
          </p>
        </div>
      </button>
    </div>
  );
}

function DropOffLocation() {
  const dispatch = useDispatch();
  const dropOffAddress = useSelector(
    (state: RootState) => state.enterAddress.dropOffAddress
  );
  const dropOffSublocality = useSelector(
    (state: RootState) => state.enterAddress.dropOffSublocality
  );
  const requestDropOffAddress = useSelector(
    (state: RootState) => state.enterAddress.requestDropOffAddress
  );
  const toggleSelected = () => {
    dispatch(setRequestDropOffAddress(!requestDropOffAddress));
  };

  const containerStyles = requestDropOffAddress
    ? "w-full h-22 space-y-4 p-6 py-4 rounded-lg border-2 border-slate-400/50 text-slate-800 bg-gray-100 cursor-not-allowed"
    : "w-full h-22 space-y-4 p-6 py-4 rounded-lg border-2 border-black text-slate-800 transition-all duration-150";

  return (
    <div className={containerStyles}>
      <p
        className={`text-slate-700 text-md font-bold ${
          requestDropOffAddress ? "text-gray-400" : ""
        }`}
      >
        Drop-off Address
      </p>
      <Address
        addressRef={dropOffAddress}
        sublocalityRef={dropOffSublocality}
        setAddress={setDropOffAddress}
        setSublocality={setDropOffSublocality}
        disabled={requestDropOffAddress}
        placeholder="Enter address"
      />
      <button onClick={toggleSelected}>
        <div className="flex space-x-4 pt-2">
          {requestDropOffAddress ? (
            <SelectedCheckbox />
          ) : (
            <NotSelectedCheckbox />
          )}
          <p className="text-left text-md">
            I need Util to securely request the{" "}
            <span className="text-blue-600">
              <b>receiver's</b>
            </span>{" "}
            address
          </p>
        </div>
      </button>
    </div>
  );
}

function Address({
  addressRef,
  sublocalityRef,
  setAddress,
  setSublocality,
  disabled,
  placeholder,
}: AddressProps) {
  const dispatch = useDispatch();
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    // Initialize Google Maps Autocomplete
    const autoComplete = new (window as any).google.maps.places.Autocomplete(
      inputRef.current,
      {
        types: ["address"],
      }
    );

    // Set the information we want to retrieve about the selected place
    autoComplete.setFields(["address_components", "formatted_address"]);

    // Add listener to handle user's address selection
    autoComplete.addListener("place_changed", () => {
      const selectedPlace = autoComplete.getPlace();
      const newAddress = selectedPlace.formatted_address;
      if (!selectedPlace.address_components) {
        dispatch(setSublocality(""));
        return;
      }

      dispatch(setAddress(newAddress));
      // Extract sublocality_level_1 information from address_components
      const sublocalityComponent = selectedPlace.address_components.find(
        (component: any) => component.types.includes("sublocality_level_1")
      );

      if (sublocalityComponent) {
        dispatch(setSublocality(sublocalityComponent.long_name));
      } else {
        dispatch(setSublocality(""));
      }
    });
  });

  function handleInputChange (e: React.ChangeEvent<HTMLInputElement>){
    dispatch(setAddress(e.target.value));
  };

  return (
    <div>
      <div
        className={`relative w-full p-3 py-3 rounded-xl border-2 ${
          disabled ? "border-slate-400" : "border-slate-800"
        }`}
      >
        <LocationOnIcon
          style={{
            position: "absolute",
            left: "10px",
            top: "50%",
            transform: "translateY(-50%)",
            color: "#18A558", // You can adjust the color accordingly
          }}
        />
        <input
          ref={inputRef}
          value={disabled ? "" : addressRef}
          onChange={handleInputChange}
          placeholder={placeholder}
          className="w-full pl-6 text-md rounded-xl border-0 focus:outline-none text-slate-700"
          style={{ background: "transparent" }}
          disabled={disabled}
        />
      </div>
      {/* {addressRef && <p>Selected Address: {addressRef}</p>}
      {sublocalityRef && <p>Sublocality: {sublocalityRef}</p>} */}
    </div>
  );
}

function AddressScreen() {
  return (
    <div
      className="flex flex-col justify-center items-center space-y-8 mb-40 my-auto mx-auto
     md:p-8 md:px-12 md:pb-56 md:space-y-8 md:border md:max-w-4xl md:rounded-xl md:shadow-xl md:mt-10 md:mb-52"
    >
      <Header />
      <PickupLocation />
      <DropOffLocation />
      <Typography variant="caption" paragraph className="text-slate-600">
        After making your delivery reservation, Util will contact you for
        additional details related to the pickup and drop-off addresses (such as
        unit numbers and entry instructions).
      </Typography>
    </div>
  );
}

export default AddressScreen;
