// PersonalInfo.tsx
import React from "react";
import { MuiTelInput } from "mui-tel-input";
import TextField from "@mui/material/TextField";
import { SelectChangeEvent } from "@mui/material/Select";
import { DeliveryRole } from "../../slices/enterAddressSlice";
import { isValidEmail, isValidFullName } from "../../utils/helperUtils";
import { useDispatch, useSelector } from "react-redux";
import { setFullName, setPhone, setEmail } from "../../slices/userSlice";
import { RootState } from "../../slices";
import { setDeliveryRole } from "../../slices/enterAddressSlice";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

// Header Component
function Header() {
  return (
    <div className="py-5">
      <h1 className="text-xl font-semibold mb-2 text-slate-700">
        Share your contact information
      </h1>
      <p className="text-slate-600 text-sm">
        Please share your contact information so we can keep you informed
        throughout the delivery.
      </p>
    </div>
  );
}

// PhoneNumber Component
function PhoneNumber() {
  const dispatch = useDispatch();
  const phone = useSelector((state: RootState) => state.user.phoneNumber);

  function handleChange(newValue: string) {
    const phoneNumberNoSpaces = newValue.replace(/\s/g, "");
    dispatch(setPhone(phoneNumberNoSpaces));
  }

  return (
    <div className="w-full space-y-2">
      <div>
        <p className="mb-2 text-xl text-slate-700">
          What is your mobile number?
        </p>
        <p className="text-slate-600 text-sm">
          We will send text updates for your delivery to this number.
        </p>
      </div>
      <MuiTelInput
        value={phone}
        onChange={handleChange}
        defaultCountry={"US"}
      />
    </div>
  );
}

function Content() {
  const dispatch = useDispatch();

  const email = useSelector((state: RootState) => state.user.email);
  const fullName = useSelector((state: RootState) => state.user.fullName);

  const [emailValidity, setEmailValidity] = React.useState<
    "success" | "error" | undefined
  >(undefined);
  const [fullNameValidity, setFullNameValidity] = React.useState<
    "success" | "error" | undefined
  >(undefined);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = e.target.value;
    setEmailValidity(isValidEmail(newEmail) ? "success" : "error");
    dispatch(setEmail(newEmail));
  };

  const handleFullNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newName = e.target.value;
    dispatch(setFullName(newName));
    setFullNameValidity(isValidFullName(newName) ? "success" : "error");
  };

  return (
    <div className="flex flex-col space-y-2 py-5 mt-4">
      <div>
        <p className="mb-2 text-xl text-slate-700">
          What is your name and email?
        </p>
        <p className="text-slate-600 text-sm">
          We will send you a receipt by email.
        </p>
      </div>
      <div className="space-y-2">
        <TextField
          id="outlined-helperText"
          label="First & Last Name"
          value={fullName}
          onChange={handleFullNameChange}
          color={fullNameValidity}
          className="w-full p-3 mb-3 text-white border border-gray-200 rounded-md"
        />
        {fullNameValidity === "error" && fullName.length > 0 && (
          <span className="text-xs font-bold text-red-600">
            Please provide your full name.
          </span>
        )}
        <TextField
          label="Email"
          value={email}
          onChange={handleEmailChange}
          color={emailValidity}
          className="w-full p-3 mb-3 text-white border border-gray-200 rounded-md"
        />
      </div>
    </div>
  );
}

function SendingOrReceiving() {
  const dispatch = useDispatch();
  const deliveryRole = useSelector(
    (state: RootState) => state.enterAddress.deliveryRole
  );

  const handleOptionChange = (e: SelectChangeEvent<string | null>) => {
    dispatch(setDeliveryRole(e.target.value));
  };

  return (
    <div className="mt-5 flex flex-col space-y-2">
      <p className="mb-2 text-xl font-medium text-slate-700">
        Are you the sender or receiver?
      </p>
      <div className="flex flex-col">
        <FormControl fullWidth>
          <InputLabel id="deliveryRole-label">Select an option</InputLabel>
          <Select
            labelId="deliveryRole-label"
            value={deliveryRole || ""}
            onChange={handleOptionChange}
            label="Select an option"
          >
            <MenuItem value={DeliveryRole.Receiver}>I am the receiver</MenuItem>
            <MenuItem value={DeliveryRole.Sender}>I am the sender</MenuItem>
          </Select>
        </FormControl>
      </div>
    </div>
  );
}

function ContactScreen() {
  return (
    <div
      className="h-screen space-y-4 mb-20 mx-auto
    md:p-8 md:px-12 md:pb-56 md:space-y-8 md:border md:max-w-4xl md:rounded-xl md:shadow-xl md:mt-10 md:mb-52
    "
    >
      <Header />
      <PhoneNumber />
      <Content />
      <SendingOrReceiving />
    </div>
  );
}

export default ContactScreen;
