// src/services/emailService.ts

import { SERVER_DOMAIN } from "../utils/constants";
import { UserState } from "../slices/userSlice";
import { DeliveryWindowState } from "../slices/deliveryWindowSlice";
import { EnterAddressState } from "../slices/enterAddressSlice";
import { SizeOfMoveState } from "../slices/sizeOfMoveSlice";

export function formatEmailResponse(userState: UserState, deliveryWindow: DeliveryWindowState, enterAddress: EnterAddressState, sizeOfMove: SizeOfMoveState, subject: string) {
  const fullName = userState.fullName.trim().split(" ");
  const firstName = fullName[0];
  const lastName = fullName.slice(1).join(" ") || "";

  const user = {
    email: userState.email,
    first_name: firstName,
    last_name: lastName,
    phone_number: userState.phoneNumber,
  };

  const transaction = {
    pickup_address: enterAddress.pickupAddress,
    pickup_sublocality: enterAddress.pickupSublocality,
    request_pickup_address: enterAddress.requestPickupAddress,
    drop_off_address: enterAddress.dropOffAddress,
    drop_off_sublocality: enterAddress.dropOffSublocality,
    request_drop_off_address: enterAddress.requestDropOffAddress,

    delivery_role: enterAddress.deliveryRole,

    delivery_size: sizeOfMove.sizeOfMove,
    selected_delivery_date: deliveryWindow.selectedDeliveryDate,
    item_description: sizeOfMove.itemDescription,

    small_item_delivery_description: deliveryWindow.smallItemDeliveryDetails,
    small_item_delivery_window: deliveryWindow.smallItemDeliveryWindow,
  };

  return { user, transaction, subject, order_id: userState.orderID };
}

export async function sendEmailToBackend(userState: UserState, deliveryWindow: DeliveryWindowState, enterAddress: EnterAddressState, sizeOfMove: SizeOfMoveState, subject: string) {
  const url = `${SERVER_DOMAIN}/v1/email/send`;
  const emailData = formatEmailResponse(userState, deliveryWindow, enterAddress, sizeOfMove, subject);
  console.log(emailData);
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(emailData),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const responseData = await response.json();
    console.log("Email data sent successfully:", responseData);
    return responseData;
  } catch (error) {
    console.error("There was a problem sending the email data:", error);
    throw error;
  }
}
