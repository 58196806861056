// Component for not selected checkbox
export function NotSelectedCheckbox() {
  return <div className="w-6 h-6 border border-slate-900 rounded"></div>;
}

export function SelectedCheckbox() {
  return (
    <div className="w-6 h-6 bg-slate-900 border border-slate-900 rounded relative">
      <div
        className="absolute inset-0 flex items-center justify-center text-white"
        style={{ fontSize: "10px" }}
      >
        ✓
      </div>
    </div>
  );
}

// Component for inactive checkbox
export function InactiveCheckbox() {
  return (
    <div className="w-6 h-6 border border-slate-400 rounded relative bg-slate-200">
      <div className="absolute top-0 left-0 w-full h-full after:absolute after:block after:w-0.5 after:h-full after:bg-slate-400 after:transform after:rotate-45 after:top-1/2 after:-translate-y-1/2"></div>
    </div>
  );
}
