// src/slices/stepSlice.ts

import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { shouldRedirectEnterAddress } from "./enterAddressSlice";
import { shouldRedirectSizeOfMove } from "./sizeOfMoveSlice";
import { shouldRedirectDeliveryWindow } from "./deliveryWindowSlice";
import { RootState } from ".";

export interface StepState {
  activeStep: number;
  canProceed: boolean;
}

export enum StepPage {
  Address,
  MoveSize,
  DeliveryWindow,
  Contact,
  PhoneVerification,
  Payment,
  Confirmation,
  AddressRedirect,
  MoveSizeRedirect,
  DeliveryWindowRedirect,
}

const initialState: StepState = {
  activeStep: StepPage.Address,
  canProceed: false,
};

const stepSlice = createSlice({
  name: "step",
  initialState,
  reducers: {
    setPageTo: (state, action: PayloadAction<number>) => {
      state.activeStep = action.payload;
    },
    allowProceed: (state) => {
      state.canProceed = true;
    },
    preventProceed: (state) => {
      state.canProceed = false;
    },
  },
});

export function getNextPage(state: RootState, dispatch: Dispatch): number {
  switch (state.step.activeStep) {
    case StepPage.Address:
      return shouldRedirectEnterAddress(state, dispatch)
        ? StepPage.AddressRedirect
        : StepPage.MoveSize;

    case StepPage.MoveSize:
      return shouldRedirectSizeOfMove(state)
        ? StepPage.MoveSizeRedirect
        : StepPage.DeliveryWindow;

    case StepPage.DeliveryWindow:
      return shouldRedirectDeliveryWindow(state)
        ? StepPage.DeliveryWindowRedirect
        : StepPage.Contact;

    case StepPage.Contact:
      return state.user.isSignedIn
        ? StepPage.Payment
        : StepPage.PhoneVerification;

    case StepPage.PhoneVerification:
      return StepPage.Payment;

    case StepPage.Payment:
      return StepPage.Confirmation;

    default:
      return -1;
  }
}

export function getPreviousPage(state: RootState): number {
  switch (state.step.activeStep) {
    case StepPage.MoveSize:
      return StepPage.Address;

    case StepPage.DeliveryWindow:
      return StepPage.MoveSize;

    case StepPage.Contact:
      return StepPage.DeliveryWindow;

    case StepPage.PhoneVerification:
      return StepPage.Contact;

    case StepPage.Payment:
      return StepPage.Contact;

    case StepPage.AddressRedirect:
      return StepPage.Address;

    case StepPage.MoveSizeRedirect:
      return StepPage.MoveSize;

    case StepPage.DeliveryWindowRedirect:
      return StepPage.DeliveryWindow;
    default:
      return -1;
  }
}

export const { setPageTo, allowProceed, preventProceed } = stepSlice.actions;
export default stepSlice.reducer;
