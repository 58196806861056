import React from "react";
import Header from "../components/HomePage/Header";
import FormController from "../components/StartMove/FormController";

function StartMove() {
  return (
    <div>
      <Header />
      <FormController />
    </div>
  );
}

export default StartMove;
