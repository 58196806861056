// src/slices/enterAddressSlice.ts

import { createSlice, Dispatch } from "@reduxjs/toolkit";
import { RootState } from ".";

export enum DeliveryRole {
  Receiver = "receiver",
  Sender = "sender",
}

export interface EnterAddressState {
  dropOffAddress: string;
  dropOffSublocality: string;
  requestDropOffAddress: boolean;
  pickupAddress: string;
  pickupSublocality: string;
  requestPickupAddress: boolean;
  deliveryRole?: DeliveryRole | null;
}

const initialState: EnterAddressState = {
  dropOffAddress: "",
  dropOffSublocality: "",
  requestDropOffAddress: false,
  pickupAddress: "",
  pickupSublocality: "",
  requestPickupAddress: false,
  deliveryRole: null,
};

const enterAddressSlice = createSlice({
  name: "enterAddress",
  initialState,
  reducers: {
    setDropOffAddress: (state, action) => {
      state.dropOffAddress = action.payload;
      if (action.payload === "") {
        state.dropOffSublocality = "";
      }
    },
    setDropOffSublocality: (state, action) => {
      state.dropOffSublocality = action.payload;
    },
    setRequestDropOffAddress: (state, action) => {
      state.requestDropOffAddress = action.payload;
    },
    setPickupAddress: (state, action) => {
      state.pickupAddress = action.payload;
      if (action.payload === "") {
        state.pickupSublocality = "";
      }
    },
    setPickupSublocality: (state, action) => {
      state.pickupSublocality = action.payload;
    },
    setRequestPickupAddress: (state, action) => {
      state.requestPickupAddress = action.payload;
    },
    setDeliveryRole: (state, action) => {
      state.deliveryRole = action.payload;
    },
  },
});

export const isAddressComplete = (enterAddress: EnterAddressState): boolean => {
  // To continue, strictly one or neither of requestDropOffAddress or requestPickupAddress should be checked.
  const isRequestValid = !(
    enterAddress.requestDropOffAddress && enterAddress.requestPickupAddress
  );

  // If the request flag is not set, then the corresponding address cannot be empty.
  const isAddressValid =
    (enterAddress.requestDropOffAddress ||
      (!enterAddress.requestDropOffAddress &&
        enterAddress.dropOffAddress !== "")) &&
    (enterAddress.requestPickupAddress ||
      (!enterAddress.requestPickupAddress &&
        enterAddress.pickupAddress !== ""));

  return isRequestValid && isAddressValid;
};

export function shouldRedirectEnterAddress(state: RootState, dispatch: Dispatch): boolean {
  const senderAddress = state.enterAddress.pickupAddress;
  const senderSublocality = state.enterAddress.pickupSublocality;
  const requestPickupAddress = state.enterAddress.requestPickupAddress;
  const receiverAddress = state.enterAddress.dropOffAddress;
  const receiverSublocality = state.enterAddress.dropOffSublocality;
  const requestDropOffAddress = state.enterAddress.requestDropOffAddress;

  if (!requestPickupAddress && senderAddress && senderSublocality !== "Manhattan") {
    return true;
  }
  if (!requestDropOffAddress && receiverAddress && receiverSublocality !== "Manhattan") {
    return true;
  }

  // Clear address that user asks Util to request
  if (requestPickupAddress && senderAddress) {
    dispatch(setPickupAddress(""))    
    dispatch(setPickupSublocality(""))
  }
  if (requestDropOffAddress && receiverAddress) {
    dispatch(setDropOffAddress(""))
    dispatch(setDropOffSublocality(""))
  }

  return false;
}

export const {
  setPickupAddress,
  setPickupSublocality,
  setRequestDropOffAddress,
  setDropOffAddress,
  setDropOffSublocality,
  setRequestPickupAddress,
  setDeliveryRole,
} = enterAddressSlice.actions;
export default enterAddressSlice.reducer;
