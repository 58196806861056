import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import "./index.css";
import "./utils/firebaseConfig";
import ReactGA from "react-ga4";
import { GOOGLE_ANALYTICS_MEASUREMENT_ID } from "./utils/constants";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <App />
);

// Load the Google Maps API
window.initGoogleAPI = function () {
  console.log("Google API loaded and ready to be used.");
};

// Initialize Google Analytics
ReactGA.initialize(GOOGLE_ANALYTICS_MEASUREMENT_ID);
