import {
  signInWithPhoneNumber,
  RecaptchaVerifier,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";
import { auth } from "../utils/firebaseConfig";

export const CAPTCHA_CONTAINER_ID = "phone-verification-container";
export const CAPTCHA_CONTAINER_PARENT_ID =
  "parent-phone-verification-container";

/**
 * Sign out the currently authenticated user.
 */
function logOutUser(): void {
  signOut(auth)
    .then(() => {
      console.log("User signed out successfully.");
    })
    .catch((error) => {
      console.error("Error signing out:", error.message);
    });
}

/**
 * Check if a user is currently signed in.
 */
function isUserSignedIn(): boolean {
  return auth.currentUser !== null;
}

/**
 * Sign out the user if they are currently signed in.
 */
function signOutIfLoggedIn(): void {
  if (isUserSignedIn()) {
    logOutUser();
  }
}

/**
 * Send a verification code to the user's phone number.
 *
 * @param {string} phoneNumber - The phone number to send the verification code to.
 * @returns {Promise} - Returns a confirmation result which can be used to verify the code.
 */
export function sendVerificationCode(phoneNumber: string): Promise<any> {
  signOutIfLoggedIn();
  console.log("auth", auth);
  const appVerifier = new RecaptchaVerifier(
    CAPTCHA_CONTAINER_ID,
    {
      size: "invisible",
    },
    auth
  );

  return signInWithPhoneNumber(auth, phoneNumber, appVerifier)
    .then((confirmationResult) => {
      return confirmationResult;
    })
    .catch((error) => {
      console.error("Error code:", error.code);
      console.error("Error message:", error.message);
      remountCaptcha();
      return null;
      // throw error;
    });
}

/**
 * Verify the user's phone using the provided verification code.
 *
 * @param {object} confirmationResult - The confirmation result returned from sending the verification code.
 * @param {string} code - The verification code entered by the user.
 * @returns {boolean} - Returns true if the verification was successful, otherwise returns false.
 */
export function confirmVerificationCode(confirmationResult: any, code: string): Promise<boolean> {
  if (!confirmationResult) {
    alert(
      "There was an error sending the verification code. Please check phone number and try again."
    );
    remountCaptcha();
    return Promise.resolve(false);
  }
  console.log("confirmationResult", confirmationResult);
  return confirmationResult
    .confirm(code)
    .then((result: any) => {
      console.log("User signed in successfully with UID:", result.user.uid);
      remountCaptcha();
      return true;
    })
    .catch((error: any) => {
      console.error("Error code:", error.code);
      console.error("Error message:", error.message);
      remountCaptcha();
      presentAlert();
      return false;
    });
}

function presentAlert() {
  alert("Incorrect code. Please check the verification code and try again.");
}
/**
 * Sign in a user using a phone number.
 *
 * @param {string} phoneNumber - The phone number to sign in with.
 */
export function loginPhoneNumber(phoneNumber: string): void {
  console.log("auth", auth);
  const appVerifier = new RecaptchaVerifier(
    CAPTCHA_CONTAINER_ID,
    {
      size: "invisible",
    },
    auth
  );

  signInWithPhoneNumber(auth, phoneNumber, appVerifier)
    .then((confirmationResult) => {
      const code = window.prompt(
        "Please enter the verification code you received via SMS:"
      );

      if (!code) {
        presentAlert();
        return;
      }

      return confirmationResult.confirm(code);
    })
    .then((result: any) => {
      console.log("User signed in successfully with UID:", result.user.uid);
      remountCaptcha();
    })
    .catch((error: any) => {
      console.error("Error code:", error.code);
      console.error("Error message:", error.message);
      remountCaptcha();
    });
}

/**
 * Remove and remount the reCAPTCHA container because the reCAPTCHA
 * cannot be reused and must be re-rendered each time the user
 * attempts to sign in.
 */

function remountCaptcha() {
  const captchaContainer = document.getElementById(CAPTCHA_CONTAINER_ID);
  if (!captchaContainer) {
    return;
  }
  captchaContainer.remove();

  const newCaptchaContainer = document.createElement("div");
  newCaptchaContainer.id = CAPTCHA_CONTAINER_ID;

  const parentElement = document.getElementById(CAPTCHA_CONTAINER_PARENT_ID);
  if (!parentElement) {
    return;
  }
  parentElement.insertBefore(newCaptchaContainer, parentElement.firstChild); // Inserting the new div as the first child of the parent element
}
