// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDQmwT-YX5vRt4VmRVth66xmDp-_8tmRhk",
  authDomain: "util-72ed5.firebaseapp.com",
  projectId: "util-72ed5",
  storageBucket: "util-72ed5.appspot.com",
  messagingSenderId: "699629426473",
  appId: "1:699629426473:web:47cd9e6cbda7d80124c938",
  measurementId: "G-T226VYWVCB",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);

export { auth };  