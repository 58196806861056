// SizeOfMove.txs
import { RootState } from "../../../slices";
import OverFiftySelection from "./OverFiftySelection";
import UnderFiftySelection from "./UnderFiftySelection";
import { useSelector } from "react-redux";
import { SizeOfMove } from "../../../slices/sizeOfMoveSlice";

function Header() {
  return (
    <div className="py-5">
      <h1 className="text-xl font-semibold mb-2 text-slate-700">
        Select a time window for your delivery
      </h1>
      <p className="text-slate-600 text-sm">
        Util assumes that both you and the other party will be available during
        this time window.
      </p>
    </div>
  );
}

function DeliveryWindow() {
  const sizeOfMove = useSelector(
    (state: RootState) => state.sizeOfMove.sizeOfMove
  );
  return (
    <div className="flex flex-col w-full h-screen mx-auto
    md:p-8 md:px-12 md:pb-56 md:space-y-8 md:border md:max-w-4xl md:rounded-xl md:shadow-xl md:mt-10 md:mb-52

    ">
      <Header />
      {sizeOfMove === SizeOfMove.Small ? (
        <UnderFiftySelection />
      ) : (
        <OverFiftySelection />
      )}
    </div>
  );
}

export default DeliveryWindow;
