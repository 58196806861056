import { SERVER_DOMAIN } from "../utils/constants";

export async function createPaymentSession(
  params: any
): Promise<string> {
  try {
    const response = await fetch(
      `${SERVER_DOMAIN}/v1/payment/checkout_session`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(params),
      }
    );

    const res = await response.json();
    return res.resp.client_secret;
  } catch (error) {
    console.error("Error creating payment:", error);
    throw error;
  }
}
