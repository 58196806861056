import * as React from "react";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { StepIconProps } from "@mui/material/StepIcon";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ScaleIcon from "@mui/icons-material/Scale";
import ScheduleIcon from "@mui/icons-material/Schedule";
import PhoneIcon from "@mui/icons-material/Phone";
import PaymentIcon from "@mui/icons-material/Payment";
import { StepPage } from "../../slices/stepSlice";

const stepsOrder = [
  StepPage.Address,
  StepPage.MoveSize,
  StepPage.DeliveryWindow,
  StepPage.Contact,
  StepPage.Payment,
];

const stepsDetails: Record<StepPage, { label: string; icon: JSX.Element }> = {
  [StepPage.Address]: {
    label: "Location",
    icon: <LocationOnIcon fontSize="small" />,
  },
  [StepPage.MoveSize]: {
    label: "Size",
    icon: <ScaleIcon fontSize="small" />,
  },
  [StepPage.DeliveryWindow]: {
    label: "Time",
    icon: <ScheduleIcon fontSize="small" />,
  },
  [StepPage.Contact]: {
    label: "Contact",
    icon: <PhoneIcon fontSize="small" />,
  },
  [StepPage.PhoneVerification]: {
    label: "Verify Phone",
    icon: <PhoneIcon fontSize="small" />,
  },
  [StepPage.Payment]: {
    label: "Confirm & Pay",
    icon: <PaymentIcon fontSize="small" />,
  },
  [StepPage.Confirmation]: {
    label: "Confirmation",
    icon: <LocationOnIcon fontSize="small" />,
  },
  [StepPage.AddressRedirect]: {
    label: "Location",
    icon: <LocationOnIcon fontSize="small" />,
  },
  [StepPage.MoveSizeRedirect]: {
    label: "Size",
    icon: <ScaleIcon fontSize="small" />,
  },
  [StepPage.DeliveryWindowRedirect]: {
    label: "Time",
    icon: <ScheduleIcon fontSize="small" />,
  },
};

const StepperConnectors = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 15,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const StepperIcon = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 30,
  height: 30,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
  }),
}));

interface UserAcquisitionTopStepperProps {
  activeStep: number;
}

function GetIcons(props: StepIconProps) {
  const { active, completed, className } = props;
  const icon = stepsDetails[stepsOrder[(props.icon as number) - 1]].icon;

  return (
    <StepperIcon ownerState={{ completed, active }} className={className}>
      {icon}
    </StepperIcon>
  );
}

export default function TopStepperController({
  activeStep,
}: UserAcquisitionTopStepperProps) {
  // Determine if any of the special steps are visible
  const isSpecialStepVisible = [
    StepPage.Address,
    StepPage.AddressRedirect,
    StepPage.MoveSize,
    StepPage.MoveSizeRedirect,
    StepPage.DeliveryWindow,
    StepPage.DeliveryWindowRedirect,
  ].includes(activeStep);

  // Set the stepper's active step based on special steps visibility
  const getStepperActiveStep = () => {
    switch (activeStep) {
      case StepPage.Address:
      case StepPage.AddressRedirect:
        return 0;
      case StepPage.MoveSize:
      case StepPage.MoveSizeRedirect:
        return 1;
      case StepPage.DeliveryWindow:
      case StepPage.DeliveryWindowRedirect:
        return 2;
      default:
        return activeStep;
    }
  };

  const stepperActiveStep = getStepperActiveStep();

  return (
    <div
      className="flex flex-col mx-auto md:max-w-6xl"
    >
      <Stack sx={{ width: "100%" }} spacing={4}>
        <Stepper
          alternativeLabel
          activeStep={stepperActiveStep}
          connector={<StepperConnectors />}
        >
          {stepsOrder.map((stepKey, index) => {
            let label = stepsDetails[stepKey].label;
            if (
              index === 0 &&
              (activeStep === StepPage.Address ||
                activeStep === StepPage.AddressRedirect)
            ) {
              label = "Location";
            } else if (
              index === 1 &&
              (activeStep === StepPage.MoveSize ||
                activeStep === StepPage.MoveSizeRedirect)
            ) {
              label = "Size";
            } else if (
              index === 2 &&
              (activeStep === StepPage.DeliveryWindow ||
                activeStep === StepPage.DeliveryWindowRedirect)
            ) {
              label = "Time";
            }
            return (
              <Step key={label}>
                <StepLabel StepIconComponent={GetIcons}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Stack>
    </div>
  );
}
