import React from "react";

function Logo() {
  const handleClick = () => {
    window.location.href = "/";
  };
  return (
    <button
      onClick={handleClick}
      className="text-5xl h-20 w-48 mx-auto md:mx-0 md:absolute top-10 left-10">
      util
    </button>
  );
}
function Header() {
  return (
    <header className="container mx-auto mt-10 px-6 text-center  md:h-20">
      <Logo />
      {/* <div className="flex items-center justify-center space-x-4 md:space-x-10 md:absolute mt-4 md:mt-0 right-10">
        <a href="#Features" className="hover:text-blue-400">
          features
        </a>
        <a href="#Features" className="hover:text-blue-400">
          contact us
        </a>
      </div> */}
    </header>
  );
}

export default Header;
