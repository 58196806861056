// src/components/StartMove/FormController.tsx

import TopStepperController from "./TopStepperController";
import AddressScreen from "./AddressScreen";
import BottomNavigationBar from "./BottomNavigationBar";
import SizeOfMove from "./MoveSizeScreen";
import DeliveryWindow from "./DeliveryWindow/DeliveryWindow";
import ContactScreen from "./ContactScreen";
import PhoneVerification from "./PhoneVerification";
import { useSelector } from "react-redux";
import { RootState } from "../../slices/index";
import ConfirmationScreen from "./ConfirmationScreen";
import { StepPage } from "../../slices/stepSlice";
import PaymentScreen from "./PaymentScreen";
import RedirectScreen, { RedirectScreenType } from "./RedirectScreen";

function FormController() {
  const activeStep = useSelector((state: RootState) => state.step.activeStep);

  function renderPage() {
    switch (activeStep) {
      case StepPage.Address:
        return <AddressScreen />;
      case StepPage.MoveSize:
        return <SizeOfMove />;
      case StepPage.DeliveryWindow:
        return <DeliveryWindow />;
      case StepPage.Contact:
        return <ContactScreen />;
      case StepPage.PhoneVerification:
        return <PhoneVerification />;
      case StepPage.Payment:
        return <PaymentScreen />;
      case StepPage.Confirmation:
        return <ConfirmationScreen />;
      case StepPage.AddressRedirect:
        return <RedirectScreen redirectType={RedirectScreenType.Address} />;
      case StepPage.MoveSizeRedirect:
        return <RedirectScreen redirectType={RedirectScreenType.MoveSize} />;
      case StepPage.DeliveryWindowRedirect:
        return <RedirectScreen redirectType={RedirectScreenType.DeliveryWindow} />;
      default:
        return <></>;
    }
  }

  return (
    <div className="px-8">
      <TopStepperController activeStep={activeStep} />
      {renderPage()}
      <BottomNavigationBar />
    </div>
  );
}

export default FormController;
