import React from "react";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import { useSelector } from "react-redux";
import { RootState } from "../../slices";
const truck = require("../../images/animations/moving_truck.json");

function MovingTuckAnimation() {
  return (
    <Player
      autoplay
      loop
      src={truck}
      style={{ height: "400px", width: "400px" }}
    >
      <Controls visible={false} buttons={[]} />
    </Player>
  );
}

function ConfirmationScreen() {
  const phoneNumber = useSelector((state: RootState) => state.user.phoneNumber);
  return (
    <div className="flex flex-col items-center justify-center">
      <MovingTuckAnimation />
      <div className="text-2xl font-bold">Thank you for your order!</div>
      <div className="text-xl text-center mt-4">
        We will send you a text as soon as possible to confirm the details of your move.
      </div>
      {/* <div className="text-xl text-center mt-4">
        We will contact you within the next{" "}
        <b className="text-slate-700">1 hour </b>to confirm your move.
      </div> */}
    </div>
  );
}

export default ConfirmationScreen;
