import { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import Typography from "@mui/material/Typography";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { createPaymentSession } from "../../services/stripeService";
import { sendEmailToBackend } from "../../services/emailService";
import { useSelector } from "react-redux";
import { RootState } from "../../slices";
import { formatEmailResponse } from "../../services/emailService";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string
);
// const stripePromise = loadStripe(
//   "pk_test_51O0R5oLy7ctG8CvexigqxJMJkQ0j08PYLqBB4LxUxvZPS2UJ0GsnRTR2ZebJdQ3dXGzqsKficg8Vl76hKQSV8d3d00LBBioGhI"
// );

function PaymentScreen() {
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const orderID = useSelector((state: RootState) => state.user.orderID);
  const email = useSelector((state: RootState) => state.user.email);

  const userState = useSelector((state: RootState) => state.user);
  const deliveryWindow = useSelector(
    (state: RootState) => state.deliveryWindow
  );
  const enterAddress = useSelector((state: RootState) => state.enterAddress);
  const sizeOfMove = useSelector((state: RootState) => state.sizeOfMove);

  async function sendEmail() {
    try {
      await sendEmailToBackend(
        userState,
        deliveryWindow,
        enterAddress,
        sizeOfMove,
        "Stripe Checkout: " + orderID
      );
    } catch (error) {
      console.error("Failed to send email:", error);
    }
  }

  function handleStripeEvent() {
    sendEmail();
  }

  useEffect(() => {
    async function fetchClientSecret() {
      const metadata = formatEmailResponse(
        userState,
        deliveryWindow,
        enterAddress,
        sizeOfMove,
        "Intent for Stripe checkout"
      );
      const params = {email: email, metadata };

      try {
        const fetchedSecret = await createPaymentSession(params);
        console.log("Client secret:", fetchedSecret);
        if (!clientSecret) {
          setClientSecret(fetchedSecret);
        }
      } catch (error) {
        console.error("Failed to get client secret:", error);
      }
    }
    fetchClientSecret();
  });

  return (
    <div className="flex flex-col justify-center w-screen mb-20 pb-20 mt-4">
      <h1 className="text-xl font-semibold mb-2 text-slate-700">
        Confirm & Pay
      </h1>
      <Typography variant="caption" paragraph>
        By confirming your reservation with Util, you agree that your delivery
        does not include any prohibited items (such as alcohol, medication,
        recreational drugs, and dangerous or illegal items).
      </Typography>
      <div className="mr-11">
        {clientSecret ? (
          <EmbeddedCheckoutProvider
            stripe={stripePromise}
            options={{ clientSecret, onComplete: handleStripeEvent }}
          >
            <EmbeddedCheckout />
          </EmbeddedCheckoutProvider>
        ) : (
          <div>Loading...</div>
        )}
      </div>
    </div>
  );
}

export default PaymentScreen;
